/* eslint-disable global-require */
import Shepherd from 'shepherd.js';

function getStepElement({ stepImg, stepTitle, stepText }) {
    const element = document.createElement('div');
    element.classList.add('step-wrapper');

    const img = document.createElement('img');
    img.src = stepImg;
    img.classList.add('step-image');

    const title = document.createElement('h3');
    title.innerText = stepTitle;
    title.classList.add('step-title');

    const text = document.createElement('p');
    text.innerText = stepText;
    text.classList.add('step-text');

    element.append(img);
    element.append(title);
    element.append(text);

    return element;
}

const TOUR = {};

export default {
    methods: {
        loadHelpTour() {
            const tour = new Shepherd.Tour({
                useModalOverlay: true,
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_hello.png'),
                    stepTitle: `Welcome, ${this.user.firstName}`,
                    stepText: 'This is an interactive learning environment, set up with everything you need to build on our APIs. We\'d love to show you around it! It should only take a minute.',
                }),
                buttons: [{
                    text: 'No thanks',
                    action: tour.cancel,
                }, {
                    text: 'Let\'s go',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_tutorial_lessons.png'),
                    stepTitle: 'Tutorial Lessons',
                    stepText: 'Each tutorial has lessons with specific objectives. These lessons will guide you on what you need to do to get things done on our APIs.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Continue',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_integrated_ide.png'),
                    stepTitle: 'Integrated IDE',
                    stepText: 'The IDE is where you will write your code as guided by the tutorial lessons. The IDE has the files you will need to work, and you can view them through the explorer.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Continue',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_run_your_code.png'),
                    stepTitle: 'Run Your Code',
                    stepText: 'After writing some code, run your code to see the results.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Continue',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_view_your_logs.png'),
                    stepTitle: 'View Your Logs',
                    stepText: 'After you run your code, use the integrated logs viewer to see your code output.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Continue',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_embedded_simulator.png'),
                    stepTitle: 'Embedded Simulator',
                    stepText: 'The simulator is a virtual phone that will help you interact with your code further and verify that all is working as expected. To start it, provide a phone number you will use for testing.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Continue',
                    action: tour.next,
                }],
            });

            tour.addStep({
                text: getStepElement({
                    stepImg: require('@/assets/img/tour_awesome.png'),
                    stepTitle: 'Nice Work!',
                    stepText: 'You\'re ready to start building. Click the help button in the upper left corner if you would like to replay these onboarding tips.',
                }),
                buttons: [{
                    text: 'Back',
                    action: tour.back,
                }, {
                    text: 'Close',
                    action: tour.complete,
                }],
            });

            tour.on('complete', this.onHelpTourComplete);
            tour.on('cancel', this.onHelpTourCancel);

            TOUR.t = tour;
            this.helpTourLoaded = true;
        },

        playHelpTour() {
            if (TOUR.t) {
                TOUR.t.start();
            }
        },
    },
};
