<template>
    <div class="h-full w-full flex flex-row justify-center relative">
        <Tutor
            v-if="tutorial && lesson"
            :tutorial="tutorial"
            :lesson="lesson"
            v-on:go-to-tutorial="onGoToTutorial"
            v-on:help="onHelp"
            v-on:go-to-previous-lesson="onGoToPreviousLesson"
            v-on:go-to-next-lesson="onGoToNextLesson"
            v-on:complete-tutorial="onCompleteTutorial"
        />

        <Ide
            v-if="tutorial && lesson"
            class="flex-1"
        />

        <Simulator
            v-if="shouldLoadSimulator()"
            class="absolute bottom-20 left-4"
        />

        <TutorialCompleted
            v-if="showTutorialCompleted"
            :tutorial="tutorial"
            :user="user"
            :rateTutorial="rateTutorial"
            :isRatingTutorial="isRatingTutorial"
            v-on:go-to-tutorial="onGoToTutorial"
        />
    </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters,
} from 'vuex';

import swal from 'sweetalert';

import {
    endSession,
} from '@/utils/api/socket/session';

import {
    setHelpTourCookie,
    getHelpTourCookie,
} from '@/utils';

import helpTour from './helpTour';

export default {
    name: 'Lesson',

    mixins: [helpTour],

    props: {
        tutorialSlug: {
            type: String,
            required: true,
        },

        lessonId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            showTutorialCompleted: false,
            helpTourLoaded: false,
        };
    },

    computed: {
        ...mapState('auth', [
            'user',
        ]),

        ...mapState('tutorials', [
            'tutorial',
            'lesson',
            'isRatingTutorial',
        ]),

        ...mapState('project', [
            'projectRunning',
        ]),

        ...mapGetters('tutorials', [
            'lessons',
        ]),
    },

    created() {
        if (this.tutorial.slug !== this.tutorialSlug) {
            this.getTutorial({ tutorialSlug: this.tutorialSlug });
        }
    },

    mounted() {
        const autoPlayHelpTour = getHelpTourCookie();
        if (autoPlayHelpTour === undefined) {
            this.loadHelpTour();
            this.playHelpTour();
        }
    },

    methods: {
        ...mapMutations('tutorials', [
            'setTutorial',
            'setLesson',
        ]),

        ...mapActions('tutorials', [
            'getTutorial',
            'updateTutorialStats',
            'rateTutorial',
        ]),

        lessonAttemptTransition(lesson) {
            const updateData = {
                runtime: this.tutorial.userData.runtime,
                stat: {
                    lastAttempted: {
                        lessonId: lesson._id,
                    },
                    completed: [
                        ...this.tutorial.userData.stat.completed,
                        ...[this.lesson._id],
                    ].reduce((base, item) => {
                        if (base.indexOf(item) === -1) {
                            base.push(item);
                        }
                        return base;
                    }, []),
                },
            };
            this.updateTutorialStats({
                tutorialId: this.tutorial._id,
                data: updateData,
            });
        },

        onGoToPreviousLesson() {
            const lessonIndex = this.lessons
                .findIndex((l) => l._id === this.lesson._id);
            if (lessonIndex === 0) return;
            const previousLessonIndex = lessonIndex - 1;
            const previousLesson = this.lessons[previousLessonIndex];
            this.setLesson(previousLesson);
        },

        onGoToNextLesson() {
            const lessonIndex = this.lessons
                .findIndex((l) => l._id === this.lesson._id);
            if ((lessonIndex + 1) === this.lessons.length) return;
            const nextLessonIndex = lessonIndex + 1;
            const nextLesson = this.lessons[nextLessonIndex];
            this.lessonAttemptTransition(nextLesson);
            this.setLesson(nextLesson);
        },

        onCompleteTutorial() {
            const completedLessons = this.tutorial.userData.stat.completed.length;

            if (completedLessons === this.lessons.length) {
                this.showTutorialCompleted = true;
                return;
            }

            const lessonIndex = this.lessons
                .findIndex((l) => l._id === this.lesson._id);
            if ((lessonIndex + 1) !== this.lessons.length) return;
            this.lessonAttemptTransition(this.lesson);
            this.showTutorialCompleted = true;
        },

        onGoToTutorial() {
            this.$router.push({
                name: 'tutorial',
                tutorialSlug: this.tutorialSlug,
            });
        },

        onHelp() {
            if (!this.helpTourLoaded) {
                this.loadHelpTour();
                this.playHelpTour();
            } else {
                this.playHelpTour();
            }
        },

        shouldLoadSimulator() {
            if (this.tutorial && this.lesson) {
                return this.lesson.layoutComponents.indexOf('simulator') !== -1;
            }
            return false;
        },

        onHelpTourComplete() {
            setHelpTourCookie(btoa('helpTour:complete'));
        },

        onHelpTourCancel() {
            setHelpTourCookie(btoa('helpTour:canceled'));
        },

        doExit(to, next) {
            if (to.name === 'tutorial') {
                this.setLesson({});
            } else {
                this.setLesson({});
                this.setTutorial({});
            }

            endSession();
            next();
        },
    },

    beforeRouteEnter(to, from, next) {
        if (from.name !== 'tutorial') {
            next({
                path: `/tutorials/${to.params.tutorialSlug}`,
            });
        } else {
            next();
        }
    },

    async beforeRouteLeave(to, from, next) {
        if (this.projectRunning) {
            const willExit = await swal({
                title: 'Are you sure?',
                text: 'Your code is running, are you sure you want to exit?',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (willExit) {
                this.doExit(to, next);
            }
        } else {
            this.doExit(to, next);
        }
    },

    components: {
        Ide: () => import('@/components/tutorials/ide/Ide.vue'),
        Tutor: () => import('@/components/tutorials/Tutor.vue'),
        TutorialCompleted: () => import('@/components/tutorials/TutorialCompleted.vue'),
        Simulator: () => import('@/components/simulator/LessonSimulator.vue'),
    },
};
</script>

<style lang="postcss">
.shepherd-content {
    border: 5px solid;
    border-color: theme('colors.orange.500');
    background-color: theme('colors.white');
    width: 500px;
}

.shepherd-text {
    padding: theme('padding.3') theme('padding.4') theme('padding.3') theme('padding.4');

    & .step-title {
        margin-top: theme('margin.4');
        font-weight: theme('fontWeight.bold');
        color: theme('textColor.gray.800');
    }

    & .step-text {
        margin-top: theme('margin.2');
        line-height: theme('lineHeight.normal');
        color: theme('textColor.gray.800');
    }
}

.shepherd-footer {
    padding: theme('padding.3') theme('padding.4') theme('padding.3') theme('padding.4');

    & button {
        font-size: theme('fontSize.sm');
        padding: theme('padding.2') theme('padding.3');

        &:first-child {
            background-color: transparent;
            color: theme('textColor.gray.600');

            &:hover {
                background-color: transparent;
                color: theme('textColor.gray.800');
            }
        }

        &:last-child {
            color: theme('textColor.white');
            font-weight: theme('fontWeight.semibold');
            background-color: theme('textColor.orange.500');

            &:hover {
                color: theme('textColor.white');
                background-color: theme('textColor.orange.700');
            }
        }
    }
}
</style>
