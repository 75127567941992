import { sessionSocket } from './socketManager';

import {
    queue as queueResponseHandler,
} from './responseQueue';

import {
    getAccessToken,
    setSessionId,
    getSessionId,
    deleteSessionId,
} from '../../auth';

const SESSION_EMIT_EVENTS = {
    INITIALIZE: 'initialize',
    SETUP: 'setup',
    SUBMIT_CODE: 'submitCode',
    EXECUTE_CODE: 'executeCode',
    STOP_EXECUTION: 'stopExecution',
    END: 'end',
};

const generateEventId = () => `evt::session::${Date.now()}`;

export const initializeSession = () => new Promise((resolve, reject) => {
    const eventId = generateEventId();
    const storedAccessToken = getAccessToken();
    const data = {
        eventId,
        accessToken: storedAccessToken,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            const {
                sessionId,
                app,
            } = res;

            setSessionId(sessionId);
            resolve({ app });
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.INITIALIZE, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const setupSession = (params) => new Promise((resolve, reject) => {
    const sessionId = getSessionId();
    const eventId = generateEventId();
    const data = {
        eventId,
        sessionId,
        ...params,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            const { host } = res;
            resolve({ host });
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.SETUP, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const submitCode = (params) => new Promise((resolve, reject) => {
    const sessionId = getSessionId();
    const eventId = generateEventId();
    const data = {
        eventId,
        sessionId,
        ...params,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            resolve();
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.SUBMIT_CODE, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const executeCode = (params, cb) => new Promise((resolve, reject) => {
    const sessionId = getSessionId();
    const eventId = generateEventId();
    const data = {
        eventId,
        sessionId,
        ...params,
    };

    const queueFactoryFunc = () => {
        queueResponseHandler(eventId, cb);
        resolve();
    };
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.EXECUTE_CODE, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const stopExecution = (cb) => new Promise((resolve, reject) => {
    const sessionId = getSessionId();
    const eventId = generateEventId();
    const data = {
        eventId,
        sessionId,
    };

    const queueFactoryFunc = () => {
        queueResponseHandler(eventId, cb);
        resolve();
    };
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.STOP_EXECUTION, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const endSession = () => {
    const sessionId = getSessionId();
    const eventId = generateEventId();
    const data = {
        sessionId,
        eventId,
    };

    const responseHandler = (res) => {
        deleteSessionId();

        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(res.message);
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    sessionSocket
        .emit(SESSION_EMIT_EVENTS.END, data)
        .then(queueFactoryFunc)
        .catch(console.error);
};
